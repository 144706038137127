import React, { useState } from "react";
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'


function HomeCard() {
  const [showData, setShowData] = useState(2);

  const handleClick = (id) => {
   if (showData === id) {
    setShowData((prevShowData) => (prevShowData === id ? prevShowData : id));
   } else {
    setShowData(id)
   }
  };

  return (
    <div id="homePage">
    <header class="nameHeader" id="nameHeader">
      <h1 class="nameHeader_title">Kenneth Chittaphong</h1>
      <p class="nameHeader_label">Developer</p>
    </header>
    
    <div className="info">
        <li className={showData === 1 ? 'highlighted' : ''} onClick={() => handleClick(1)}>Projects</li>
        <li className={showData === 2 ? 'highlighted' : ''} onClick={() => handleClick(2)}>About</li>
        <li className={showData === 3 ? 'highlighted' : ''} onClick={() => handleClick(3)}>Contact</li>
      {showData === 1 && (
        <div className="project_list">
          <a className="project_item text-btn" href="https://bill-share.netlify.app/" target="_blank" rel="noopener noreferrer">
            <div className="project_title">Bill Share</div>
            <div className="project_info">JavaScript React / 2022</div>
          </a>
          <a className="project_item text-btn" href="https://voyage-books.netlify.app/" target="_blank" rel="noopener noreferrer">
            <div className="project_title">Voyage Books</div>
            <div className="project_info">JavaScript React / Python / Django / 2023</div>
          </a>
    
        </div>
      )}
      {showData === 2 && (
          <div className="about-container">
          <div>Started out in the finance and accounting industry. After dwelling into coding and the magic it has on creativity and technology, is when I had a change of heart.</div>
        </div>
      )}
      {showData === 3 && (
          <div className="about-container">
            <a className="project_item text-btn" href="https://www.linkedin.com/in/kennethchittaphong/" target="_blank" rel="noopener noreferrer">
            <div className="project_title"><BsLinkedin /></div>
            </a>
            <a className="project_item text-btn" href="https://github.com/kennethchittaphong" target="_blank" rel="noopener noreferrer">
            <div className="project_title"><BsGithub /></div>
            </a>
            <a className="project_item text-btn" href="mailto:kennethchittaphong">
            <div className="project_title"><MdEmail /></div>
            </a>
        </div>
      )}
    </div>
    </div> 
  );
}

export default HomeCard;
