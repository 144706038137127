import './App.css';
import React from 'react';
import HomeCard from './components/HomeCard';

function App() {
  return (
    <div>
      <HomeCard />
    </div>
  );
}

export default App;
